import Mainpage from "./Mainpage"

function App() {
  return (
  <>
  <Mainpage/>
  </>
  );
}

export default App;
